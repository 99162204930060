<template>
  <div class="app-container">
    <eHeader
      :query="query"
      :sites="sites"
      :sup_this="sup_this"
      :srCmsContentTypes="srCmsContentTypes"
      :srCmsTags="srCmsTags"
    />
    <!--表格渲染-->
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50px"></el-table-column>
      <el-table-column prop="id" label="编号" />
      <el-table-column prop="siteName" label="站点名称" width="120px"/>
      <el-table-column prop="srCmsContentTypeName" label="内容类型" />
      <el-table-column prop="title" label="标题" width="300px" />
      <!-- <el-table-column prop="author" label="作者"/> -->
      <el-table-column prop="language" label="语言">
        <template slot-scope="scope">
          <span>{{scope.row.language=='en_US'?'English':'中文' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isShare" label="共享">
        <template slot-scope="scope">
          <span>{{scope.row.isShare=='1'?'是':'否' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isTop" label="置顶">
        <template slot-scope="scope">
          <span>{{scope.row.isTop=='1'?'是':'否' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isRecommend" label="推荐">
        <template slot-scope="scope">
          <span>{{scope.row.isRecommend=='1'?'是':'否' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态">
        <template slot-scope="scope">
          <span>{{scope.row.contentStatus==1?'通过':scope.row.contentStatus==0?'待审核':scope.row.contentStatus==3?'已归档':'审核拒绝'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" >
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" align="center">
        <template slot-scope="scope">
          <edit
            v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_EDIT'])"
            :data="scope.row"
            :sup_this="sup_this"
            :sites="sites"
            :srCmsContentTypes="srCmsContentTypes"
            :srCmsTags="srCmsTags"
          />
          <el-popover
            v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180"
          >
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button
                :loading="delLoading"
                type="primary"
                size="mini"
                @click="subDelete(scope.row.id)"
              >确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"
    />

    <div class="bnt">
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_CHECK'])"
        type="success"
        @click="changeContentStatus(1)"
      >审核通过</el-button>
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_CHECK'])"
        type="danger"
        @click="changeContentStatus(2)"
      >审核拒绝</el-button>
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_SHARE'])"
        type="success"
        @click="changeIsShare(1)"
      >共享</el-button>
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_SHAER'])"
        type="danger"
        @click="changeIsShare(0)"
      >取消共享</el-button>
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_RECOMMEND'])"
        type="success"
        @click="changeIsRecommend(1)"
      >推荐</el-button>
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_RECOMMEND'])"
        type="danger"
        @click="changeIsRecommend(0)"
      >取消推荐</el-button>
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_TOP'])"
        type="success"
        @click="changeIsTop(1)"
      >置顶</el-button>
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_TOP'])"
        type="danger"
        @click="changeIsTop(0)"
      >取消置顶</el-button>
    </div>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { getSitesData } from "@/api/cms/srSite";
import { getSrCmsContentTypeTree } from "@/api/cms/srCmsContentType";
import { getTagsData } from "@/api/cms/srCmsTagType";
import {
  del,
  changeContentStatusAction,
  changeIsRecommendAction,
  changeIsTopAction,
  changeIsShareAction
} from "@/api/cms/srCmsContent";
import { parseTime } from "@/utils/index";
import eHeader from "@/components/cms/content/header";
import edit from "@/components/cms/content/edit";
export default {
  name:'content',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      multipleSelection: [],
      sites: [],
      srCmsContentTypes: [],
      srCmsTags: []
    };
  },
  created() {
    this.getSites();
    this.getTags();
    this.getSrCmsContentTypes();
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "cms/crm/srCmsContent";
      const sort = "id,desc";
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query;
      const title = query.title;
      const contentStatus = query.contentStatus;
      const siteId = query.siteId;
      const contentType = query.contentType;
      const language = query.language;
      if (title !== "" && title !== null) {
        this.params["title"] = title;
      }
      if (contentStatus !== "" && contentStatus !== null) {
        this.params["contentStatus"] = contentStatus;
      }
      if (siteId !== "" && siteId !== null) {
        this.params["siteId"] = siteId;
      }
      if (contentType !== "" && contentType !== null) {
        this.params["contentType"] = contentType;
      }
      if (language !== "" && language !== null) {
        this.params["language"] = language;
      }
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getSites() {
      const params = {page:0,size:1000}
      getSitesData(params).then(res => {
        this.sites = res.content;
      });
    },
    getSrCmsContentTypes() {
      getSrCmsContentTypeTree().then(res => {
        this.srCmsContentTypes = [];
        const srCmsContentType = { id: 0, label: "root-根", children: [] };
        srCmsContentType.children = res;
        this.srCmsContentTypes.push(srCmsContentType);
      });
    },
    getTags() {
      getTagsData().then(res => {
        for (let i = 0; i < res.content.length; i++) {
          this.srCmsTags.push({
            id: res.content[i].id,
            label: res.content[i].typeNameEn + "-" + res.content[i].typeName
          });
        }
      });
    },
    changeContentStatus(data) {
      const ids = [];
      this.multipleSelection.forEach(element => {
        ids.push(element.id);
      });
      this.formData = {
        ids: ids,
        contentStatus: data
      };
      changeContentStatusAction(JSON.stringify(this.formData)).then(res => {
        if (res.status == 10000) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
        } else {
          this.$message.error("操作失败");
        }
        this.init();
      });
    },
    changeIsShare(data) {
      const ids = [];
      this.multipleSelection.forEach(element => {
        ids.push(element.id);
      });
      this.formData = {
        ids: ids,
        isShare: data
      };
      changeIsShareAction(JSON.stringify(this.formData)).then(res => {
        if (res.status == 10000) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
        } else {
          this.$message.error("操作失败");
        }
        this.init();
      });
    },
    changeIsRecommend(data) {
      const ids = [];
      this.multipleSelection.forEach(element => {
        ids.push(element.id);
      });
      this.formData = {
        ids: ids,
        isRecommend: data
      };
      changeIsRecommendAction(JSON.stringify(this.formData)).then(res => {
        if (res.status == 10000) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
        } else {
          this.$message.error("操作失败");
        }
        this.init();
      });
    },
    changeIsTop(data) {
      const ids = [];
      this.multipleSelection.forEach(element => {
        ids.push(element.id);
      });
      this.formData = {
        ids: ids,
        isTop: data
      };
      changeIsTopAction(JSON.stringify(this.formData)).then(res => {
       if (res.status == 10000) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
        } else {
          this.$message.error("操作失败");
        }
        this.init();
      });
    }
  }
};
</script>

<style scoped>
.bnt {
  margin-top: 20px;
  text-align: center;
}
.el-button {
  margin-right: 20px;
}
</style>
