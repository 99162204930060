<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false" :sites="sites" :srCmsContentTypes="srCmsContentTypes" :srCmsTags="srCmsTags"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    srCmsContentTypes: {
      type: Array,
      required: true
    },
    srCmsTags: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      yesData:[],
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        siteId: this.data.siteId,
        contentType:this.data.contentType,
        contentUrl: this.data.contentUrl,
        coverImage:this.data.coverImage,
        annexName: this.data.annexName,
        videoTime:this.data.videoTime,
        title: this.data.title,
        subTitle: this.data.subTitle,
        description: this.data.description,
        contentDetail: this.data.contentDetail,
        tag: this.data.tag,
        isRecommend: this.data.isRecommend.toString(),
        isTop: this.data.isTop.toString(),
        isShow: this.data.isShow.toString(),
        isShare: this.data.isShare==null?'0':this.data.isShare.toString(),
        isCharge:this.data.isCharge.toString(),
        lecturesTime:this.data.lecturesTime,
        lecturesCity:this.data.lecturesCity,
        lecturesAddr:this.data.lecturesAddr,
        clicks: this.data.clicks,
        sortNo: this.data.sortNo,
        publishPeople: this.data.publishPeople,
        author: this.data.author,
        language:this.data.language,
        createTime: this.data.createTime,
        updateTime: this.data.updateTime,
        contentStatus: this.data.contentStatus.toString(),
        status: this.data.status.toString(),
        remark: this.data.remark,
        fileList:[],
        fileList1:[],
        yesData:this.data.tag==null?[]:this.data.tag.split(',').map(Number),
      }
      let vm = this;
      if(this.data.coverImage!=null && this.data.coverImage!="") { //封面
          var obj={}
          var index=this.data.coverImage.lastIndexOf('/')
          vm.$set(obj,'name',this.data.coverImage.substring(index+1));
          vm.$set(obj,'url',this.data.coverImage);  //修改files里面的结构（name,url）
          _this.form.fileList.push(obj);
      }
      if(this.data.annexName!=null && this.data.annexName!="") { //后台文件名
          var obj={}
          var index=this.data.annexName.lastIndexOf('/')
          vm.$set(obj,'name',this.data.annexName.substring(index+1));
          vm.$set(obj,'url',this.data.annexName);  //修改files里面的结构（name,url）
          _this.form.fileList1.push(obj);
      }
      _this.dialog = true
    },
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
