import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'cms/crm/srCmsContent',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'cms/crm/srCmsContent/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'cms/crm/srCmsContent',
    method: 'put',
    data
  })
}

export function changeContentStatusAction(data) {
  return request({
    url: 'cms/crm/srCmsContent/changeContentStatus',
    method: 'post',
    data
  })
}

export function changeIsRecommendAction(data) {
  return request({
    url: 'cms/crm/srCmsContent/changeIsRecommend',
    method: 'post',
    data
  })
}

export function changeIsTopAction(data) {
  return request({
    url: 'cms/crm/srCmsContent/changeIsTop',
    method: 'post',
    data
  })
}

export function changeIsShareAction(data) {
  return request({
    url: 'cms/crm/srCmsContent/changeIsShare',
    method: 'post',
    data
  })
}
