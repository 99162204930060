<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" v-if="dialog" :title="isAdd ? '新增' : '编辑'" width="1200px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="150px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="站点名称" prop="siteId">
            <el-select v-model="form.siteId" filterable placeholder="请选择" style="width: 250px;">
              <el-option
                v-for="item in sites"
                :key="item.id"
                :label="item.siteNameEn+'-'+item.siteName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="内容类型" prop="contentType">
            <treeselect v-model="form.contentType" :options="srCmsContentTypes" style="width: 200px;" placeholder="选择内容类型"/>
          </el-form-item>
        </el-col>
         <el-col :span="8">
          <el-form-item label="语言/language" prop="language">
            <el-select v-model="form.language" filterable placeholder="请选择" style="width: 200px;">
              <el-option
                v-for="item in languages"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-form-item label="外部链接">
        <el-input v-model="form.contentUrl" style="width: 500px;"/>
      </el-form-item>
       -->

      <el-row>
        <el-col :span="12">
          <el-form-item label="页面标题" prop="title">
            <el-input v-model="form.title" style="width: 500px;"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="子标题">
            <el-input type="textarea" autosize v-model="form.subTitle" style="width: 370px;"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="描述文字">
            <el-input type="textarea" autosize v-model="form.description" style="width: 600px;"/>
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="图片封面">
            <el-upload
              ref="upload"
              class="upload"
              :action="uploadUrl"
              :on-success="handleSuccess1"
              :limit=1
              multiple
              method:="post"
              :file-list="form.fileList">
              <el-button size="small" type="primary" plain>选择图片</el-button>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="附件上传">
            <el-upload
              ref="upload"
              class="upload"
              :action="uploadUrl"
              :on-success="handleSuccess"
              :limit=1
              multiple
              method:="post"
              :file-list="form.fileList1">
              <el-button size="small" type="primary" plain>选择文件</el-button>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="视频时长">
            <el-input v-model="form.videoTime" style="width: 100px;" placeholder="例：02:31"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="Tag标签" v-model="form.tag">
        <el-transfer
            v-model="form.yesData"
            filterable
            :props="{key: 'id',label: 'label'}"
            :titles="['未选中', '已选中']"
            :data="srCmsTags">
        </el-transfer>
      </el-form-item>

      <!-- <el-row>
        <el-col :span="8">
          <el-form-item label="是否推荐" prop="isRecommend">
            <el-radio v-model="form.isRecommend" label=1>是</el-radio>
            <el-radio v-model="form.isRecommend" label=0>否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否置顶" prop="isTop">
            <el-radio v-model="form.isTop" label=1>是</el-radio>
            <el-radio v-model="form.isTop" label=0>否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否首页显示" prop="isShow">
            <el-radio v-model="form.isShow" label=1>是</el-radio>
            <el-radio v-model="form.isShow" label=0>否</el-radio>
          </el-form-item>
        </el-col>
      </el-row> -->

      <el-row v-if="this.lecturesIsShow">
        <el-col :span="8">
          <el-form-item label="讲座时间" >
            <el-input v-model="form.lecturesTime" placeholder="July 25, 2019, 14:32:56"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="讲座城市">
            <el-input v-model="form.lecturesCity" placeholder="Los Angeles, USA"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="讲座地址">
            <el-input v-model="form.lecturesAddr" placeholder="18 Chinatown, United States"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-row>
        <el-col :span="8">
          <el-form-item label="是否共享" prop="isShare">
            <el-radio v-model="form.isShare" label=1>是</el-radio>
            <el-radio v-model="form.isShare" label=0>否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否收费" prop="isCharge">
            <el-radio v-model="form.isCharge" label=1>是</el-radio>
            <el-radio v-model="form.isCharge" label=0>否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="收费金额">
            <el-input v-model="form.chargeAmount" style="width: 100px;"/>（单位：元）
          </el-form-item>
        </el-col>
      </el-row> -->
      <el-row>
        <el-col :span="16">
           <el-form-item label="作者">
            <el-input v-model="form.author" style="width: 370px;"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排序号">
            <el-input v-model="form.sortNo" style="width: 50px;"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="点击数" hidden>
        <el-input v-model="form.clicks" style="width: 50px;"/>
      </el-form-item>


      <el-form-item label="创建时间" hidden>
        <el-input v-model="form.createTime" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="修改时间" hidden>
        <el-input v-model="form.updateTime" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="审核状态" prop="status" hidden>
        <el-radio v-model="form.contentStatus" label=0>待审核</el-radio>
        <el-radio v-model="form.contentStatus" label=1>通过</el-radio>
        <el-radio v-model="form.contentStatus" label=2>未通过</el-radio>
      </el-form-item>
      <el-form-item label="状态" prop="status" hidden>
        <el-radio v-model="form.status" label=0>删除</el-radio>
        <el-radio v-model="form.status" label=1>正常</el-radio>
        <el-radio v-model="form.status" label=2>回收站</el-radio>
      </el-form-item>
      <el-form-item label="备注" hidden>
        <el-input type="textarea" v-model="form.remark" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="内容">
        <quillEditor @catchData="catchData" :defaultValues="form.contentDetail"></quillEditor>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit('form')">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/cms/srCmsContent'
import { uploadFile} from '@/api/system/dims'
import quillEditor from '@/components/tools/quillEditor'
import { mapGetters } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import IconSelect from '@/components/IconSelect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: { quillEditor,Treeselect, IconSelect},
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    sites: {
      type: Array,
      required: true
    },
    srCmsContentTypes: {
      type: Array,
      required: true
    },
    srCmsTags: {
      type: Array,
      required: true
    },
    yesData:{
      type: Array,
    },
  },
  computed: {
    ...mapGetters([
      'name'
    ])
  },
  data() {
    return {
      lecturesIsShow:false,
      loading: false, dialog: false,
      defaultValues:"",
      form: {
        id: '',
        siteId: '',
        contentUrl: '',
        contentType:'0',
        coverImage:'',
        annexName: '',
        videoTime:'',
        title: '',
        subTitle: '',
        description: '',
        contentDetail:'',
        tag: '',
        isRecommend: '0',
        isTop: '0',
        isShow: '0',
        isShare: '0',
        isCharge:'0',
        lecturesTime:'',
        lecturesCity:'',
        lecturesAddr:'',
        clicks: '',
        sortNo: '',
        publishPeople: '',
        author:'',
        language:'',
        createTime: '',
        updateTime: '',
        contentStatus: '0',
        status: '1',
        remark: '',
        fileList:[],
        fileList1:[],
        yesData:[]
      },
      languages: [{
        value: 'zh_CN',
        label: '中文/chinese'
      }, {
        value: 'en_US',
        label: '英语/english'
      }],
      rules: {
        siteId: [
          { required: true, message: '请选择站点', trigger: 'blur' },
        ],
        language: [
          { required: true, message: '请选择语言', trigger: 'blur' },
        ],
        contentType: [
          { required: true, message: '请选择内容类型', trigger: 'blur' },
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 1, max: 400, message: "长度在 1 到 400 个字符(200个汉字)", trigger: "blur" }
        ],
      },
      uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
    }
  },
  watch: {
    'form.contentType'(val) {
      if(this.form.contentType==11){
        this.lecturesIsShow=true;
      }else{
        this.lecturesIsShow=false;
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
      // this.sup_this.init();
    },
    doSubmit(form) {
      this.loading = true
      this.form.tag='';
      for(let i=0;i<this.form.yesData.length;i++){
        if(i==0){
          this.form.tag = this.form.yesData[i];
        }else{
          this.form.tag += ','+this.form.yesData[i];
        }
      }
      if (this.isAdd) {
        this.doAdd(form)
      } else this.doEdit(form)
    },
    doAdd(form) {
       this.$refs[form].validate(valid => {
        if (valid) {
          add(this.form).then(res => {
            this.resetForm()
            this.$notify({
              title: '添加成功',
              type: 'success',
              duration: 2500
            })
            this.loading = false;
            this.sup_this.init();
            // this.$router.go(0);
          }).catch(err => {
            this.loading = false
          })
        } else {
          this.loading = false;
        }
      });
    },
    doEdit(form) {
       this.$refs[form].validate(valid => {
        if (valid) {
          edit(this.form).then(res => {
            this.resetForm()
            this.$notify({
              title: '修改成功',
              type: 'success',
              duration: 2500
            })
            this.loading = false;
            this.sup_this.init();
          }).catch(err => {
            this.loading = false
          })
        } else {
          this.loading = false;
        }
      });
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        siteId: '',
        contentUrl: '',
        contentType:'0',
        coverImage:'',
        annexName: '',
        videoTime:'',
        title: '',
        subTitle: '',
        description: '',
        contentDetail:'',
        tag: '',
        isRecommend: '0',
        isTop: '0',
        isShow: '0',
        isShare: '0',
        isCharge:'0',
        lecturesTime:'',
        lecturesCity:'',
        lecturesAddr:'',
        clicks: '',
        sortNo: '',
        publishPeople: '',
        author:'',
        language:'',
        createTime: '',
        updateTime: '',
        contentStatus: '0',
        status: '1',
        remark: '',
        yesData:[]
      }
      this.defaultValues='';
    },
    catchData(value){
      //在这里接受子组件传过来的参数，赋值给data里的参数
      this.form.contentDetail=value;
    },
    handleSuccess(response){
        //上传成功要处理的事
        this.form.annexName=response.data.content[0].url;
    },
    handleSuccess1(response){
        //上传成功要处理的事
        this.form.coverImage=response.data.content[0].url;
    },
  }
}
</script>

<style>
.el-transfer{
  width: 800px;
}
.el-transfer-panel{
  width: 350px;
  height: 500px;
}
.el-transfer-panel__list.is-filterable{
  height: 380px;
}
.el-transfer-panel__body{
  height: auto;
}
.dialog-footer{
  margin-top: 40px;
}
</style>
