import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'cms/crm/srCmsTagType',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'cms/crm/srCmsTagType/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'cms/crm/srCmsTagType',
    method: 'put',
    data
  })
}

// 获取所有标签
export function getTagsData() {
  return request({
    url: 'cms/crm/allSrCmsTag',
    method: 'get'
  })
}
