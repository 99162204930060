<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-input v-model="query.title" clearable placeholder="标题" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select v-model="query.contentStatus" clearable placeholder="审核状态" class="filter-item" style="width: 120px" @change="toQuery">
      <el-option v-for="item in contentStatusOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-select v-model="query.siteId" clearable placeholder="站点名称" class="filter-item" style="width: 150px" @change="toQuery">
      <el-option v-for="item in sites" :key="item.id" :label="item.siteName" :value="item.id"/>
    </el-select>
    <el-select v-model="query.language" clearable placeholder="语言/language" class="filter-item" style="width: 150px" @change="toQuery">
      <el-option v-for="item in languages" :key="item.value" :label="item.label" :value="item.value"/>
    </el-select>
    <treeselect v-model="query.contentType" :options="srCmsContentTypes" class="contentType" placeholder="选择内容类型" search @keyup.enter.native="toQuery"/>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSCONTENT_ALL','SRCMSCONTENT_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true">新增内容</el-button>
      <eForm ref="form" :is-add="true" :sites="sites" :srCmsContentTypes="srCmsContentTypes" :srCmsTags="srCmsTags" :sup_this="sup_this"/>
    </div>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
import Treeselect from '@riophae/vue-treeselect'
import IconSelect from '@/components/IconSelect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: { eForm , Treeselect, IconSelect},
  props: {
    query: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    srCmsContentTypes: {
      type: Array,
      required: true
    },
    srCmsTags: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      contentStatusOptions:[
        { key: '0', display_name: '待审核' },
        { key: '1', display_name: '审核通过' },
        { key: '2', display_name: '审核拒绝' },
      ],
      languages:[
        {"value":"en_US","label":"English"},
        {"value":"zh_CN","label":"中文"}
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    },
  }
}
</script>
<style>
.contentType{
  display:inline-block;
  vertical-align: top;
  width: 200px;
  font-size: 14px;
}
.contentType .vue-treeselect__control{
  height: 31px;
}
.contentType .vue-treeselect__placeholder{
  line-height: 31px;
}
.contentType .vue-treeselect__single-value{
  line-height: 31px;
}
</style>

